import BaseModel from '@/libs/base/dataModel/BaseModel';

export class OfflineAlwaysAnalysisDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 设备编号
    this.deviceCode = '';
    // 设备名称
    this.name = '';
    // 在线状态（0-离线 1-在线）
    this.isOnline = '';
    // 在线状态（0-离线 1-在线）
    this.isOnlineDesc = '';
    // 设备启用状态（0-禁用 1-启用）
    this.status = '';
    // 设备启用状态描述
    this.statusDesc = '';
    // 累计离线次数
    this.offlineNum = '';
    // 小区名称
    this.communityName = '';
    // 省名称
    this.provinceName = '';
    // 市名称
    this.cityName = '';
    // 区名称
    this.districtName = '';
    // 办事处名称
    this.officeName = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    this.offlineNum = resData.offlineNum - 0;
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const statusArr = ['禁用', '启用'];
    const isOnlineArr = ['离线', '在线'];
    for (let i = 0; i < len; i++) {
      const dic = {
        deviceCode: 'KHJKHJKHKHK' + BaseModel.getFakeNumber().toFixed(0),
        name: BaseModel.getFakeTitle(8),
        status: i % 2,
        statusDesc: statusArr[i % 2],
        isOnline: i % 2,
        isOnlineDesc: isOnlineArr[i % 2],
        offlineNum: BaseModel.getFakeNumber().toFixed(0),
        communityName: BaseModel.getFakeTitle(8),
        provinceName: BaseModel.getFakeTitle(3),
        cityName: BaseModel.getFakeTitle(3),
        districtName: BaseModel.getFakeTitle(3),
        officeName: BaseModel.getFakeTitle(8)
      };
      list.push(dic);
    }
    return list;
  }
}
