<!--
    Created by 程雨喵'mac on 2024/12/3.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：数据分析中使用
-->
<style lang="less">

</style>

<template>
  <div>
    <div>对表格中的{{ data.length }}条数据进行分析</div>
    <div>
      <div>端口使用率分析</div>
      <v-percent />
    </div>
  </div>
</template>

<script>
import vPercent from './view/percent.vue';
export default {
  components: { vPercent },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    /**
     * [{key: '', title: ''}]
     */
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {};
  }
};
</script>
