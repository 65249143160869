/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 数据统计
 */

import BaseApi from '@/request/base/BaseApi';

class DataStatisticsApiSet {
  constructor () {
    this.getDaysOverview = (date) => {
      const api = BaseApi.createModel('今日', '/opmp/statis/days/overview').setMethod(1);
      api.params.date = date;
      return api;
    };
    this.getStatisticsList = BaseApi.createModel('数据分析-设备经营报表查询', '/opmp/statis/device/list').setMethod(1);
    this.getCommunityList = BaseApi.createModel('数据分析-小区经营报表查询', '/opmp/statis/community/list').setMethod(1);
    this.getOfficeList = BaseApi.createModel('数据分析-办事处经营报表查询', '/opmp/statis/office/list').setMethod(1);
    this.getLongTermOfflineList = BaseApi.createModel('长期离线设备列表', '/opmp/device/longTermOfflineList').setMethod(1);
    this.getHighOfflineList = BaseApi.createModel('高频离线设备列表', '/opmp/device/highOfflineList').setMethod(1);
  }
}

export default new DataStatisticsApiSet();
