<!--
    Created by 程雨喵'mac on 2024/12/3.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：展示占比
-->
<style lang="less">
.data-statics-percent {
  font-size: 11px;
  .charts-percent-header {
    padding-left: 100px;
    .percent-header-row {
      display: flex;
      .col {
        text-align: center;
        .flex-grow(33.3333%);
      }
    }
  }
  .charts-percent-box {
    margin: 0 0 @containerGap;
    position: relative;
    display: flex;
    height: 120px;
    .qujian-box {
      .flex-grow(100px);
      height: 100%;
      .qujian {
        position: absolute;
        left: 90px;
        right: 0;
        border-top: 1px dashed @lineColorNormal;
        span {
          position: absolute;
          .display-flex();
          padding: 0 4px;
          top: -14px;
          height: 14px;
          transform: translateX(-100%);
          border: 1px dashed @lineColorNormal;
          &.show-bottom {
            top: -1px;
          }
        }
      }
    }
    .percent-box {
      flex: 1;
      display: flex;
      height: 100%;
      .col-1, .col-2, .col-3 {
        display: flex;
        flex-direction: column;
        .flex-grow(33.3333%);
        height: 100%;
        border-right: 1px solid @lineColorNormal;
      }
      .col-1 {
        .top { background-color: rgba(84,110,253,0.5)}
        .mid { background-color: rgba(84,110,253,0.3)}
        .bottom { background-color: rgba(84,110,253,0.1)}
      }
      .col-2 {
        .top { background-color: #9ee0fc; z-index: 1}
        .bottom { background-color: #ebf9fe; z-index: 1}
      }
      .col-3 {
        .top { background-color: #92e9db; z-index: 1}
        .bottom { background-color: #e9fbf8; z-index: 1}
      }
      //.top {
      //  background-color: red;
      //}
      //.mid {
      //  background-color: green;
      //}
      //.bottom {
      //  background-color: blue;
      //}
      .top, .mid, .bottom {
        position: relative;
        flex: 1;
        .display-flex();
      }
    }
  }
}
</style>

<template>
  <div class="data-statics-percent">
    <!--<div class="total"></div>-->
    <div class="charts-percent-header">
      <div class="percent-header-row">
        <div class="col">
          区间占比
        </div>
        <div class="col">
          中位数相关
        </div>
        <div class="col">
          平均数相关
        </div>
      </div>
    </div>
    <div class="charts-percent-box">
      <div class="qujian-box">
        <div
          class="qujian"
          style="top: 0"
        >
          <span>{{ valueList[0].label }}</span>
        </div>
        <div
          class="qujian"
          :style="`top:${percentData.range.qujian1.percent}%`"
        >
          <span>{{ valueList[1].label }}</span>
        </div>
        <div
          class="qujian"
          :style="`top:${percentData.range.qujian1.percent + percentData.range.qujian2.percent}%`"
        >
          <span class="show-bottom">{{ valueList[2].label }}</span>
        </div>
        <div
          class="qujian"
          style="bottom: 0"
        >
          <span class="show-bottom">{{ valueList[3].label }}</span>
        </div>
      </div>
      <div class="percent-box">
        <div class="col-1">
          <div
            class="top"
            :style="`max-height:${percentData.range.qujian1.percent}%;min-height:${percentData.range.qujian1.percent}%`"
          >
            占比:{{ percentData.range.qujian1.percent }}%
          </div>
          <div
            class="mid"
            :style="`max-height:${percentData.range.qujian2.percent}%;min-height:${percentData.range.qujian2.percent}%`"
          >
            占比:{{ percentData.range.qujian2.percent }}%
          </div>
          <div class="bottom">
            占比:{{ percentData.range.qujian3.percent }}%
          </div>
        </div>
        <div class="col-2">
          <div
            class="top"
            :style="`max-height:${percentData.mid.big.percent}%;min-height:${percentData.mid.big.percent}%`"
          >
            占比:{{ percentData.mid.big.percent }}%
          </div>
          <div class="bottom">
            占比:{{ percentData.mid.small.percent }}%
          </div>
        </div>
        <div class="col-3">
          <div
            class="top"
            :style="`max-height:${percentData.ave.big.percent}%;min-height:${percentData.ave.big.percent}%`"
          >
            占比:{{ percentData.ave.big.percent }}%
          </div>
          <div class="bottom">
            占比:{{ percentData.ave.small.percent }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from '@/libs/utils';

export default {
  components: {},
  props: {

  },
  data () {
    return {
      pageData: {
        // 大于中位数-条数
        midBigValue: 0,
        // 大于中位数-百分比
        midBigPercent: 0,
        // 小于中位数-条数
        midSmallValue: 0,
        // 小于中位数-百分比
        midSmallPercent: 0,
        // 大于平均值-条数
        aveBigValue: 0,
        // 大于平均值-百分比
        aveBigPercent: 0,
        // 小于平均值-条数
        aveSmallValue: 0,
        // 小于平均值-百分比
        aveSmallPercent: 0
      },
      percentData: {},
      // 总览()
      valueList: []
    };
  },
  created () {
    const list = [];
    let max = 0;
    let min = 9999999;
    let sum = 0;
    let ave = 0;
    // 中位数(偶数个时是中间两位的平均值，奇数个时为最中间的数)
    let mid = 0;
    for (let i = 1; i < 50; i++) {
      // list.push({ value: i });
      list.push({ value: i * (2 + i) });
    }
    list.sort(sortBy('value', 0));
    list.forEach(v => {
      const value = v.value;
      if (max <= value) {
        max = value;
      }
      if (min >= value) {
        min = value;
      }
      sum += value;
    });
    ave = Number((sum / list.length).toFixed(4));
    if (list.length % 2 === 0) {
      const half = list.length / 2;
      // 偶数个
      mid = Number(((list[half].value + list[half - 1].value) / 2).toFixed(4));
    } else {
      // 奇数个
      mid = list[Math.floor(list.length / 2)].value;
    }
    // console.log(list.map(v => v.value));
    console.log(max, min, mid, ave);
    // 建立区间
    // const valueDic = { max, min, mid, ave };
    const valueList = [
      { label: '最大值', key: 'max', value: max },
      { label: '最小值', key: 'min', value: min },
      { label: '中位数', key: 'mid', value: mid },
      { label: '平均值', key: 'ave', value: ave }
    ].sort(sortBy('value', 0));
    const dic = {
      // 中位数
      mid: {
        // 大于中位数
        big: { list: [], percent: 0 },
        // 不大于中位数
        small: { list: [], percent: 0 }
      },
      ave: {
        // 大于中位数
        big: { list: [], percent: 0 },
        // 不大于中位数
        small: { list: [], percent: 0 }
      },
      // 区间值
      range: {
        qujian1: { list: [], percent: 0 },
        qujian2: { list: [], percent: 0 },
        qujian3: { list: [], percent: 0 }
      }
    };
    list.forEach(v => {
      const value = v.value;
      if (value >= mid) {
        // 大于等于 中位数
        dic.mid.big.list.push(v);
      } else {
        dic.mid.small.list.push(v);
      }
      if (value >= ave) {
        // 大于等于 平均数
        dic.ave.big.list.push(v);
      } else {
        dic.ave.small.list.push(v);
      }
      const qujian1 = valueList[1].value;
      const qujian2 = valueList[2].value;
      if (value <= max && value >= qujian1) {
        // max ~ 区间1
        dic.range.qujian1.list.push(v);
      } else if (value < qujian1 && value >= qujian2) {
        // 区间1 ~ 区间2
        dic.range.qujian2.list.push(v);
      } else {
        // 区间2 ~ min
        dic.range.qujian3.list.push(v);
      }
    });

    // 计算百分比
    dic.mid.big.percent = Number((dic.mid.big.list.length / list.length * 100).toFixed(2));
    dic.mid.small.percent = Number((100 - dic.mid.big.percent).toFixed(2));
    dic.ave.big.percent = Number((dic.ave.big.list.length / list.length * 100).toFixed(2));
    dic.ave.small.percent = Number((100 - dic.ave.big.percent).toFixed(2));
    dic.range.qujian1.percent = Number((dic.range.qujian1.list.length / list.length * 100).toFixed(2));
    dic.range.qujian2.percent = Number((dic.range.qujian2.list.length / list.length * 100).toFixed(2));
    dic.range.qujian3.percent = Number((100 - dic.range.qujian1.percent - dic.range.qujian2.percent).toFixed(2));

    this.percentData = dic;
    this.valueList = valueList;
  }
};
</script>
