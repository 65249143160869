<!--
    Created by 程雨喵'mac on 2024/11/28.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    页面名称：高频离线报表
-->
<style lang="less">
#offline-always-analysis {

}
</style>

<template>
  <div id="offline-always-analysis">
    <yg-split-vertical-box
      v-model="isOpen"
      :show-open-button="tableData.length > 0"
    >
      <div
        slot="left"
        class="split-box"
      >
        <!-- 查询条件 -->
        <div v-permission="'system:statis:highOfflineList:list'">
          <yg-search-area
            :show-info="false"
            :value="searchOption"
            :is-stealth="true"
            :show-rest="false"
            @on-search="_clickSearch"
            @on-change-stealth="tableResizeFlag = !tableResizeFlag"
          />
        </div>
        <yg-simple-table
          v-permission="'system:statis:highOfflineList:list'"
          :control-resize="tableResizeFlag"
          :top-columns="tableTopColumns"
          :top-title="topTitle"
          :columns="tableColumns"
          :export-condition="settingExportCondition"
          :export-setting="settingExportItem"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
      <div
        slot="right"
        class="right-box"
      >
        <v-charts-analysis
          v-if="isOpen"
        />
      </div>
    </yg-split-vertical-box>
  </div>
</template>

<script>
import DataStatisticsApiSet from '@/request/api/DataStatisticsApiSet';
import { OfflineAlwaysAnalysisDataModel } from './model/OfflineAlwaysAnalysisDataModel';
import ColumnMixin from './mixin/tableColumn';
import vChartsAnalysis from '@/pages/106__dataStatistics/_components/charts/index.vue';
import { getLastDate } from '@/libs/utils';

export default {
  components: { vChartsAnalysis },
  mixins: [ColumnMixin],
  data () {
    return {
      searchOption: {
        deviceCode: BaseSearchModel.initData('设备编码', 'deviceCode', 'Input').setExclusions(['communityName', 'status', 'officeCode', 'duration', 'region']),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        status: BaseSearchModel.initData('启用状态', 'status', 'Select').setData([
          { value: 1, label: '已启用' },
          { value: 0, label: '已停用' }
        ]),
        officeCode: BaseSearchModel.initData('吾行办事处', 'officeCode', 'YgGlobalSelect').setData('officeList'),
        duration: BaseSearchModel.initData('累计离线次数', 'duration', 'YgRangeInput').setDefault(['', '']).setIsNumberRange().setUnit('次').setExclusions(['deviceCode']),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2),
        time: BaseSearchModel.initData('统计日期范围', 'time', 'DatePickerRange').setDefault([getLastDate(0), getLastDate(0)]).setClearable(false).setDatePickerElevator([])
      },
      settingExportCondition: {},
      settingExportItem: BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(40).setPermission('system:statis:highOfflineList:export'),
      isOpen: false,
      page: { current: 1, total: 0 },
      tableData: [],
      // table重新渲染
      tableResizeFlag: false
    };
  },
  computed: {
    topTitle () {
      if (this.page.total) {
        return `高频离线设备(设备总数量:${this.page.total})`;
      }
      return '高频离线设备';
    }
  },
  created () {},
  mounted () {
    console.log(this.$el.clientWidth);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    getRequestParams () {
      const s = this.searchOption;
      // const searchStartTime = new Date(s.time.value[0]).getTime();
      // const earliestTime = new Date('2024-04-01 00:00:00').getTime();
      // const searchEndTime = new Date(s.time.value[1]).getTime();
      // const deadlineDate = getLastDate(0);
      // const deadlineTime = new Date(deadlineDate + ' 23:59:59').getTime();
      // // 最早时间为24年4月1日，最晚时间为昨日
      // if (searchStartTime < earliestTime) {
      //   return {
      //     status: false,
      //     msg: '开始日期最早为2024年04月01日',
      //     params: {}
      //   };
      // }
      // if (searchEndTime > deadlineTime) {
      //   return {
      //     status: false,
      //     msg: '结束日期最晚为' + getLastDate(0),
      //     params: {}
      //   };
      // }
      return {
        status: true,
        msg: '',
        params: {
          deviceCode: s.deviceCode.value,
          communityName: s.communityName.value,
          status: s.status.value,
          officeCode: s.officeCode.value,
          province: s.region.value[0] || '',
          city: s.region.value[1] || '',
          district: s.region.value[2] || '',
          beginNum: s.duration.value[0] || '',
          endNum: s.duration.value[1] || '',
          beginTime: s.time.value[0] || '',
          endTime: s.time.value[1] || ''
        }
      };
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {},
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const paramsData = this.getRequestParams();
      if (!paramsData.status) {
        return this.$Message.warning(paramsData.msg);
      }
      const api = DataStatisticsApiSet.getHighOfflineList;
      api.params = paramsData.params;
      api.params.pageNo = this.page.current;
      api.params.pageSize = 200;
      this.tableData = [];
      this.page.total = 0;

      this.settingExportCondition = api.params;

      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = OfflineAlwaysAnalysisDataModel.initListWithDataList(resData.rows);
      });
      // setTimeout(() => {
      //   const resList = OfflineAlwaysAnalysisDataModel.createFakeDataList(200);
      //   this.page.total = resList.length * 2;
      //   this.tableData = OfflineAlwaysAnalysisDataModel.initListWithDataList(resList);
      // }, 300);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
